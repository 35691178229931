<template>
  <v-container class="is-md py-12">
    <!-- header -->
    <v-row align="end" class="mb-3">
      <v-col>
        <v-btn
          plain small :ripple="false" text
          :to="{name: 'Users search'}"
          class="unwidth px-0"
        >
          <v-icon class="mr-1">
            mdi-keyboard-backspace
          </v-icon>
          Back to users
        </v-btn>
      </v-col>

      <v-spacer />

      <v-col class="d-flex align-center justify-end">
        <v-btn
          light color="primary--gradient" :loading="loading.user"
          class="px-2 unwidth" small @click="fetchApiData()"
        >
          <v-icon class="mr-1" small>
            mdi-sync
          </v-icon>
          Refresh
        </v-btn>
      </v-col>
    </v-row>

    <!-- loaders -->
    <v-row v-if="!user">
      <v-col cols="2">
        <v-skeleton-loader height="80" type="image" />
      </v-col>
      <v-col cols="5">
        <v-skeleton-loader height="80" type="image" />
      </v-col>
      <v-col cols="5">
        <v-skeleton-loader height="80" type="image" />
      </v-col>

      <v-col cols="12">
        <v-skeleton-loader class="mb-7" height="120" type="image" />
        <v-skeleton-loader class="mb-7" height="180" type="image" />
        <v-skeleton-loader class="mb-7" height="120" type="image" />
        <v-skeleton-loader type="image" />
      </v-col>
    </v-row>

    <!-- user -->
    <v-row v-else>
      <!-- basic user info -->
      <v-col cols="12" md="7" class="d-flex">
        <UserAvatar :user="user" size="81" class="rounded mr-4" tile />
        <v-card color="grey700" class="d-flex align-center rounded px-4 py-2 flex-grow-1">
          <UserLevel class="text-h6 mr-4" :level="user.level" />
          <div class="meta mr-1 full-width">
            <Username no-level class="text-h6 lh-1-3 font-weight-bold" :user="user" />
            <UserXPBar top :xp="user.xp" />
          </div>
        </v-card>
      </v-col>

      <v-col cols="12" md="5">
        <v-card color="grey700" class="rounded px-4 py-4 full-height d-flex flex-column justify-center">
          <h4 class="text-body-2 mb-2">
            <v-icon small class="primary500--text text-body-1 mr-2">
              mdi-calendar-account
            </v-icon>
            <span class="grey200--text mr-2">Joined:</span>
            <v-tooltip top color="primary500">
              <template #activator="{on}">
                <span v-on="on">{{ user.createdAt | relativeTime('twitter') }}</span>
              </template>
              <span>{{ user.createdAt | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
            </v-tooltip>
          </h4>

          <h4 class="text-body-2">
            <v-icon small class="primary500--text text-body-1 mr-2">
              mdi-calendar-start
            </v-icon>
            <span class="grey200--text mr-2">Last login:</span>
            <v-tooltip top color="primary500">
              <template #activator="{on}">
                <span v-on="on">{{ user.lastLogin | relativeTime }}</span>
              </template>
              <span>{{ user.lastLogin | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
            </v-tooltip>
          </h4>
        </v-card>
      </v-col>

      <!-- stats -->
      <v-col cols="12">
        <v-card color="grey800" class="pa-4">
          <h4 class="text-body-1 font-weight-semibold mb-3">
            Statistics
          </h4>
          <v-container fluid class="px-0">
            <v-row justify="center">
              <v-col
                v-for="i in 5" :key="i"
                cols="12" md="4"
              >
                <v-card flat color="grey600" class="pa-4">
                  stats
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>

      <!-- stats -->
      <v-col cols="12">
        <v-card color="grey800" class="pa-4">
          <h4 class="text-body-1 font-weight-semibold mb-3">
            Add or subtract balance
          </h4>
          <v-container fluid class="px-0">
            <v-row justify="center">
              .
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { LEVELS } from '@/utils/constants'
import UserXPBar from '@/components/UserXPBar'

export default {
  metaInfo() {
    const { user } = this
    return {
      title: `User: ${user?.name} - ${user?._id}`,
    }
  },
  components: {
    UserXPBar,
  },
  data() {
    return {
      loading: {
        user: false,
      },
      user: null,
    }
  },
  computed: {
    ...mapGetters({
      session: 'auth/session',
    }),
  },
  created() {
    this.fetchApiData(this.$route.params.id)
  },
  methods: {
    async fetchApiData(userId = this.user?._id) {
      try {
        this.loading.user = true
        const user = await this.$socket.request('admin.user.get', userId)

        this.user = {
          ...user,
          level: this.getLevel(user.xp),
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.user = false
      }
    },
    getLevel(xp) {
      const nextLevel = LEVELS.findIndex(lvl => lvl > xp ?? 0)

      // we return nextLevel because index starts at 0 and first level is 1
      return (nextLevel !== -1 ? nextLevel : LEVELS.length) - 1
    },
    onInspectUser(user) {
      console.log(user)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .user-xp {
  max-width: 250px;

  .xp-bar {
    height: 6px;
    background-color: grey(900) !important;
  }
}
</style>
